<template>

    <div class="min-h-screen flex flex-col" v-cloak>
        <loading-indicator v-if="!initialized" class="mt-64" />
        <div class="flex-grow pt-8 px-8 text-l" v-if="initialized">
            <div class="flex items-center justify-between mb-4">
                <div class="flex items-center justify-start customers">
                    <h2 class="text-4xl font-bold mr-8">{{$t('translations.views.pos.customer.customers.title')}}</h2>
                    <!--                    <div class="">-->
                    <div class="relative">
                        <div class="flex justify-center items-center">
                            <div class="relative flex items-center flex-grow search-bar mr-4">
                                <input type="text" @keyup="search()" @click="setField('searchquery')"
                                       autocomplete="off" placeholder="Zoeken" v-model="searchquery"
                                       name="searchquery"
                                       readonly
                                       class="" />
                            </div>
                            <button @click="searchClear()"
                                    class="button button-blur mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <button @click="search()"
                                    class="button button-blur">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                        <div class="vue-keyboard-wrapper shadow" :class="[field == 'searchquery' ? 'block' : 'hidden']">
                            <keyboard
                                v-model="input"
                                @input="changed"
                                :layouts="[
                                    '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                                    '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                                ]"
                                :maxlength="16"
                            ></keyboard>
                        </div>
                        </div>

                    </div>
                    <!--                    </div>-->
                </div>
                <div class="flex">
                    <div class="flex items-center mr-8">
                        <button @click="customer_recent = !customer_recent;search()" type="button"
                                :class="{'selected': customer_recent }"
                                class="button button-blur mr-4">
                                {{$t('translations.views.pos.customer.customers.recent')}}
                        </button>
                        <button @click="customer_regular = !customer_regular;search()" type="button"
                                :class="{'selected': customer_regular }"
                                class="button button-blur mr-4">
                                {{$t('translations.views.pos.customer.customers.fixed')}}
                        </button>
                        <button @click="customer_balancenegative = !customer_balancenegative;search()"
                                :class="{'selected': customer_balancenegative}" type="button"
                                class="button button-blur">
                                {{$t('translations.views.pos.customer.customers.open')}}
                        </button>
                    </div>
                    <a @click="close()" class="screen-close">
                        <svg class="w-14 h-14 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="#303039"
                             viewBox="0 0 24 24"
                             stroke="#303039">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </a>
                </div>
                <!-- <div class="col-span-1">
                    <a @click="close()">
                        <svg class="w-14 h-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </a>
                </div> -->
            </div>

            <div class="list-secondary clients" @click="setField()">
                <ul class="">
                    <li class="list-item cursor-pointer" v-for="(customer, index) in customers" v-bind:key="index"
                        :class="{'selection': customer_selected.id == customer.id}"
                        @click="customerSelect(customer)">
                        <a class="block">
                            <div class="">
                                <div class="min-w-0 flex-1 flex items-center justify-between">
                                    <div class="truncate">
                                        <div class="flex text-base font-medium">
                                            {{ customer.attributes.searchquery }}
                                        </div>
                                    </div>
                                    <div class="flex-shrink-0 ml-5">
                                        <span class="font-bold text-xl"
                                            :class="[customer.attributes.balancedue < 0 ? 'text-red-500' : 'text-green-500']">
                                            <currency/>{{ customer.attributes.balancedue }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <div v-if="customers.length < this.pagination.total" @click="customersGet" class="w-full py-3 font-bold border-2 border-sjefgreen-400 mt-5 rounded-md">load more</div>
            </div>
        </div>

        <footer class="button-bar text-xl" v-if="initialized">
            <div class="flex items-center justify-between mb-4">
                <div class="flex items-center justify-start p-2 pl-6 pr-10 space-x-4">
                    <a v-if="customer_selected.id" @click="customerOpen()"
                       class="button button-blur">
                       {{$t('translations.views.pos.customer.customers.open')}}
                    </a>
                    <a v-if="customer_selected.id" @click="virtualTableOpen()"
                       class="button button-blur">
                       {{$t('translations.views.pos.customer.customers.open-virtual-table')}}
                    </a>
                    <a v-if="customer_selected.id" @click="transactionCreate()"
                       class="button button-blur">
                       {{$t('translations.views.pos.customer.customers.top-up-balance')}}
                    </a>
                    <!-- <modal-table-reset-spending-all @resetted="reload()" /> -->
                </div>
                <div class="flex items-center justify-end py-2 px-10 space-x-4">
                    <a @click="customersBalanceduePrint()"
                       class="button button-blur">
                       {{$t('translations.views.pos.customer.customers.print-balance')}}
                    </a>
                    <modal-customer-create @openvirtualtable="customerSelectAndvirtualTableOpen($event)" />
                </div>
            </div>
        </footer>
    </div>

</template>

<script>
import axios from 'axios';

// mixins
import KeyboardHelper from '../../../mixins/keyboard.helper.js';

// print
import PrintCustomersBalancedue from '../../../prints/customers-balancedue';

// components
import LoadingIndicator from '../../../components/LoadingIndicator';
import ModalCustomerCreate from '../../../components/ModalCustomerCreate.vue';

export default {
    name: 'Customers',
    mixins: [KeyboardHelper],
    components: {
        LoadingIndicator,
        ModalCustomerCreate,
    },

    data () {
        return {
            searchquery: '',
            initialized: false,
            customers: [],
            customer_selected: {},
            customer_recent: false,
            customer_regular: false,
            balancenegative: false,
            pagination: {
                page: 1,
                total: 0
            },
        };
    },
    mounted () {
        this.customers = [];
        this.customersGet();
    },

    methods: {


        close () {
            this.$store.dispatch('transaction/setPrestine', true);
            this.$router.push({ name: 'pos' });
        },

        search () {
            // this.field = null
            this.customersGet();
        },


        changed (value) {
            if (value.length > 0)
                this[this.field] += value;
            else {
                this[this.field] = this[this.field].slice(0, -1);
            }
            this.search();
        },

        searchClear () {
            this.field = null;
            this.searchquery = '';
            this.customersGet();
        },

        reload () {
            location.reload();
        },

        refresh () {
            this.customersGet();
        },

        async transactionCreate () {

            // set cashfunction
            let cashfunction_id = this.$store.getters['settings/cashfunctionBalanceCustomerId'];

            // check if cashfunction is set
            if (!cashfunction_id) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Geen kasfunctie gekoppeld",
                    text: "Koppel een kasfunctie aan klanten saldo",
                }, 2000);
                return;
            }

            let amount = this.customer_selected.attributes.balancedue * -1;

            this.$store.dispatch('transaction/clear');
            this.$store.dispatch('transaction/setType', 'cash_inout');
            this.$store.dispatch('transaction/setCashfunction', this.$store.getters['settings/cashfunctionBalanceCustomerId']);
            this.$store.dispatch('transaction/setCustomer', this.customer_selected);

            this.$router.push({ name: 'transaction', params: { 'amount': amount > 0 ? amount : '' } });
        },

        async customersGet () {
            this.areas = [];
        
            axios.get('/customers', {
                params: {
                    regular: this.customer_regular ? 1 : 0,
                    recent: this.customer_recent ? 1 : 0,
                    balancenegative: this.customer_balancenegative ? 1 : 0,
                    searchquery: this.searchquery,
                    page: this.pagination.page,
                },
                
            }).then(response => {
                // this.customers = this.customers.concat(response.data.data);
                this.customers = response.data.data;
                // this.pagination.page ++
                // this.pagination.total = response.data.pagination.total
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
                this.initialized = true;
            });
        },

        customerSelect (customer) {
            if (this.customer_selected.id == customer.id) {
                this.customer_selected = {};
                return;
            }
            this.customer_selected = customer;
        },

        customerOpen () {
            if (!this.customer_selected) {
                this.$notify({ group: "notifications", type: 'error', title: "Selecteer een klant", text: "" }, 2000);
                return;
            }

            this.$store.dispatch('transaction/setCustomer', this.customer_selected);
            this.$router.push({ name: 'pos' });

            this.customerTouch();
        },

        async customerTouch () {
            await axios.patch('/customers/' + this.customer_selected.id + '/touch').then(() => {
            }).catch(error => {
                console.log(error);
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        async customersBalanceduePrint () {
            await axios.get('/customers/balancedue/negative').then(response => {
                if (response.data.data.length == 0) {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Geen openstaande saldo's",
                        text: "",
                    }, 2000);
                    return;
                }
                new PrintCustomersBalancedue().print(response.data.data);
            }).catch(error => {
                console.log(error);
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        customerSelectAndvirtualTableOpen (customer) {
            this.customer_selected = customer;
            this.virtualTableOpen();
        },

        async virtualTableOpen () {
            if (!this.customer_selected) {
                this.$notify({ group: "notifications", type: 'error', title: "Selecteer een klant", text: "" }, 2000);
                return;
            }
            await axios.post('/tables/virtual/open', {
                customer_id: this.customer_selected.id,
            }).then(response => {

                this.$store.dispatch('transaction/setTable', response.data.data);
                this.$router.push({ name: 'pos' });

            }).catch(error => {
                console.log(error);
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
                this.customerTouch();
            });
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
};
</script>
