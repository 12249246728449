<template>
    <a @click="open()" class="button button-filled">
        {{$t('translations.components.modal-customer-create.title')}}
    </a>
    <div v-if="active" class="fixed z-20 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                    <div class="modal-content">

                      <modal-close @close="close()"/>

                        <div class="flex justify-between items-center mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    {{$t('translations.components.modal-customer-create.new-customer')}}
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div class="flex justify-between mb-8 default-input">
                                <Field type="text" ref="searchquery" @click="setField('searchquery')" focus placeholder="Naam *" v-model="searchquery" name="searchquery" class="create-client mr-8 flex-grow" :class="{'error': errors.searchquery}" />
                                <div @click="regular = !regular" class="col-span-4 pl-3 flex justify-between items-center">
                                    <span class="mr-4">{{$t('translations.components.modal-customer-create.regular-customer')}}</span>
                                    <button type="button" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-green-500" role="switch" aria-checked="false">
                                        <!-- <span aria-hidden="true" class="pointer-events-none absolute w-full h-full rounded-md"></span> -->
                                        <span aria-hidden="true" :class="[regular ? 'bg-green-500' : 'bg-gray-200']" class="pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"></span>
                                        <span aria-hidden="true" :class="[regular ? 'translate-x-5' : 'translate-x-0']" class="pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"></span>
                                    </button>
                                </div>
                            </div>
                            <!-- <div class="mb-5 grid grid-cols-12">
                                <div class="col-span-8">
                                    <Field type="text" @click="setField('notes')" v-model="notes" placeholder="Notitie" name="notes" class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :class="{'error': errors.notes}" />
                                </div>
                            </div> -->
                            <keyboard
                            v-model="input"
                            @custom="custom"
                            @input="changed"
                            :layouts="[
                                '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                                '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                            ]"
                            :maxlength="16"
                            ></keyboard>
                        </div>
                    </div>
                    <div class="modal-footer button-large flex">
                        <a @click="close()"
                           class="button button-danger flex-grow button-large mr-4">
                           {{$t('translations.components.modal-customer-create.close')}}
                        </a>
                        <button type="submit"
                                class="button button-large button-filled flex-grow">
                                {{$t('translations.components.modal-customer-create.save')}}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

import { Field, Form } from 'vee-validate';
import * as yup from "yup";

// mixins
import KeyboardHelper from '../mixins/keyboard.helper.js';
import ModalClose from './ModalClose.vue';

export default {
    mixins: [KeyboardHelper],
    emits: ['openvirtualtable'],
    components: {
        ModalClose,
        Field,
        Form,
    },
    data () {
        const schema = yup.object().shape({
            searchquery: yup.string().required().max(255),
            notes: yup.string(),
        });

        return {
            input: '',
            active: false,
            schema,
            field: 'searchquery',

            searchquery: '',
            openvirtualtable: true,
            regular: false,
            notes: '',
        }
    },

    methods: {

        open() {
            this.active = true
        },

        close() {
            this.active = false
        },

        async onSubmit() {

            axios.post('/customers', {
                searchquery: this.searchquery,
                regular: this.regular,
                notes: this.notes,
            }).then(response => {
                if (this.openvirtualtable) {
                    this.$emit('openvirtualtable', response.data.data)

                    return
                }
                this.$notify({ group: "notifications", type: 'success', title: response.data.data.attributes.searchquery + " aangemaakt", text: "" }, 2000);
                this.close()

            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },

    },
}
</script>
