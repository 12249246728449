import moment from 'moment';
import store from '../store'
import {add as addPrintjob} from '../lib/printerqueue';

export default class PrintCustomersBalancedue {
    print(customers) {
        // eslint-disable-next-line no-undef
        var builder = new StarWebPrintBuilder();

        // Create Request
        var request = '';
        // var paperwidth = 'inch3DotImpact';

        request += builder.createInitializationElement();

        request += builder.createTextElement({ characterspace: 2 });
        request += builder.createAlignmentElement({ position: 'center' });

        request += builder.createTextElement({ emphasis: true });

        let self = this
        request += builder.createTextElement({ data: "Klanten Saldo's" });
        request += builder.createTextElement({ data: '\n' });

        request += builder.createTextElement({
            emphasis: false
        });
        request += builder.createTextElement({ data: moment().format('HH:mm:ss DD-MM-YYYY') + '\n' });

        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ emphasis: false });

        request += builder.createAlignmentElement({ position: 'left' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        // Headings
        if (store.getters['printer/paperwidth'] == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('KLANT', 17) + '  ' + self.format_string('SALDO', 6, 'right')
            });
        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: self.format_string('KLANT', 28) + '  ' + self.format_string('SALDO', 6, 'right')
            });
        }
        request += builder.createTextElement({
            data: '\n',
            emphasis: false
        });

        // Lines
        if (store.getters['printer/paperwidth'] == '58mm') {
            customers.forEach(function (line) {
                request += builder.createTextElement({
                    data: self.format_string(line.attributes.label, 17) + '  ' + self.format_string(parseFloat(line.attributes.balancedue).toFixed(2), 6, 'right') + '\n'
                });
            })

        } else if (store.getters['printer/paperwidth'] == '82mm' || store.getters['printer/paperwidth'] == '72mm') {
            customers.forEach(function (line) {
                request += builder.createTextElement({
                    data: self.format_string(line.attributes.label, 28) + '  ' + self.format_string(parseFloat(line.attributes.balancedue).toFixed(2), 6, 'right') + '\n'
                });
            })
        }
        request += builder.createCutPaperElement({
            feed: true,
            type: 'full',//store.printer.autocutter,
        });

        // add to queue
        addPrintjob(request);

        return;
    }


    format_string(value, length, align = 'left') {
        let str = value.toString();

        while (str.length < length) {
            if (align == 'right') {
                str = ' ' + str;
            } else {
                str = str + ' ';
            }
        }
        if (str.length <= length) {
            return str
        }
        return str.slice(0, length)
        // return string;

    }
}
