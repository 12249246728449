<template>
  <div class="modal-close" @click="close()">
    <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="#303039" viewBox="0 0 24 24" stroke="#303039">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
</template>


<script>
    export default {
        emits: ['close'],

        methods: {
            close () {
                this.$emit('close', {
                })
            },
        },
    };
</script>